#login-admin{
	//padding-top: 0;
}
#admin{
	section{
		padding-left: 1em;
		padding-right: 1em;
		padding-bottom: 2em;
	}
	
	#page-title{
		padding-top:3em;
		h1{
			font-size: rem-calc(18);
			text-transform: uppercase;
			margin:0;
		}
		h2{
			font-size: rem-calc(24);
			font-weight: 600;
			margin:0;
		}
		h3{
			font-size: rem-calc(20);
			margin:0;
			font-weight: 600;
		}
		& + section{
			padding-top:0;
		}
	}
	.top-bar-title{
		padding:20px;
	}
	.logo{
		
		@include breakpoint(large) {
			width: 320px;
		}
	}
	input{
		height: 3rem;
		margin-bottom: 0.2em;
	}
	textarea{
		
		margin-bottom: 0.2em;
	}

	.top-bar {
	  display: -webkit-flex;
	  display: flex;
	  -webkit-align-items: center;
	  align-items: center;
	}
	.top-bar .top-bar-title {
	  margin-right: auto;
	}
} 

.login-title{
	@extend .fs-7;
	margin-bottom: 2rem;
} 


.feed{
	margin-top: 2rem;
	border-top: 1px solid #c7c7c7;
}
.feed-post{
	border-bottom: 1px solid #c7c7c7;
	padding:10px;

}
.feed-post__title{
	 
	font-weight: 600;
}
.feed-post__date{
	font-size: 12px;

}
.feed-post__name{

}


 

#candidate{}

.info-block{
	background: $light-blue;
	padding: 1rem;
	p, a{
		margin-bottom: 0;
		line-height: 1.2;
	}
}
.status{
	@extend .info-block;
	margin-bottom: 2em;
	@extend .grid-x;
	div{
		@extend .cell;
	}
	&>div:nth-child(2n+1){
		@extend .medium-8;

	}
	&>div:nth-child(2n){
		@extend .medium-4;
		text-align: right;
		@include breakpoint(small down) {
			text-align: left;
			margin-top:0.5rem;
		}

	}
}
.status-title{
	font-size: 16px;
	line-height: 1.2;
}
.status-current{
	font-size: 18px;
	font-weight: 600;
	line-height: 1.2;
}
.status-btn{
	font-size: 14px;
	margin:0;
}


.col-title{
	font-size: 20px;
	@include breakpoint(medium down) {
		//margin-left: -0.5rem;
		border-bottom: 1px solid;
		text-transform: uppercase;
	}
}
.section-title, .event-title{
	font-size: 18px;
	font-weight: 600;
}

.timeline-event{
	margin-bottom: 1rem;

}

.event-date{
	font-size: 12px;
	//font-style: italic;
	margin-bottom: 0.5rem;
}


.grid-t, .grid-c{
	div{
		 
		margin-bottom: 0.4em;
		@extend .small-12;

	}
}





	.grid-t{
		@extend .grid-x;
		div{
			@extend .cell;
		}
	 }
	.grid-c{
		@extend .grid-x;

		div:nth-child(2n+1){
			@extend .cell;
			
			
			
			@include breakpoint(medium down) {
			 	font-size: 70%;
			    text-transform: uppercase;
			    margin-bottom:0;
			    margin-top: 0.8rem;
			}
			@include breakpoint(large) {
				 
				text-align: right;
			    padding-right: 1em;
			     
			}
			
		}
		div:nth-child(2n){
			@extend .cell;
		}
	}



#candidato{
	.grid-t{
		div{
			@extend .large-8;
			@extend .medium-12;
			@extend .large-offset-4;
		}
	 }

	.grid-c{
		
		margin-bottom: 1rem;
		div:nth-child(2n+1){
			@extend .large-4;
			@extend .medium-12;

		}
		div:nth-child(2n){
			@extend .large-8;
			@extend .medium-12;
		}
	}
}




#candidate-edit{
	.grid-t{
 		& > div{
			@extend .large-9;
			@extend .large-offset-3;
		}
	 }
	.grid-c{
		& > div:nth-child(2n+1){
 			@extend .large-3;
 			padding-top:0.6em;
		}
		& > div:nth-child(2n){
 			@extend .large-9;
		}
	}
}

#vacante-edit{
	.grid-t{
 		& > div{
			@extend .large-9;
			@extend .large-offset-3;
		}
	 }
	.grid-c{
		& > div:nth-child(2n+1){
 			@extend .large-3;
 			padding-top:0.6em;
		}
		& > div:nth-child(2n){
 			@extend .large-9;
		}
	}
}


#prueba-edit{
	.grid-file{
 		& > div{
			@extend .large-10;
			@extend .large-offset-2;
		}
	 }
	.grid-c{
		& > div:nth-child(2n+1){
 			@extend .large-2;
 			padding-top:0.6em;
		}
		& > div:nth-child(2n){
 			@extend .large-10;
		}
	}
}

