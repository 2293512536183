fieldset{
      width: 100%;
}


// inputs con label chevres
.inputcontainer {
  position: relative;
  padding-top: 1em;
  margin-top:-1em;
  //all textish inputs
  input:not([type=submit]):not([type=file]),
  textarea {
    width: 100%;
    overflow: hidden;
    //make placeholder disappear on focus
    &:focus::-webkit-input-placeholder {
      color: white;
    }
    &:focus:-moz-placeholder {
      color: white;
    }
    &:focus::-moz-placeholder {
      color: white;
    }
    &:focus:-ms-input-placeholder {
      color: white;
    }
    & + label {
      
      
    }
    &:focus, &:not(:empty), &:not(:placeholder-shown) {
      & + label {
        z-index:1;
        transform: translateY(-80%);
        color: #8a8a8a;
        background:white;
      }
    }
  }
  label {
    position:absolute;
    display:inline-block;
    top: 23px;
    left:1rem;
    z-index:-1;
    font-size: 1em;
 	margin-left: -6px;
    padding: {
      left:6px;
      right:6px;      
    }
    color: #fff;
    transition: all 200ms;
        white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 95%;
  }
}

fieldset{
	legend{
		padding: 1rem 0;
	}
}

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea {
 	height: 4rem;   
    padding: 1rem;
}

.recover-mail{
	    height: 2.4375rem;
    padding: 0.5rem;
}



/*
.file-upload {
  position: relative;
  display: inline-block;
}
*/
.file-upload__label {
  
  /*
  display: block;
  padding: 1em 2em;
  color: #fff;
  background: #222;
  border-radius: .4em;
  transition: background .3s;
  
  &:hover {
     cursor: pointer;
     background: #000;
  }*/
}
    
.file-upload__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width:0;
    height: 100%;
    opacity: 0;
}


//checkboxes


.checkbox input[type="checkbox"] {
    opacity: 0;
    display: none;
}

.checkbox label {
    position: relative;
    display: inline-block;
    line-height: 32px;

    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 34px;
    margin:0;
}

.checkbox label::before,
.checkbox label::after {
    position: absolute;
    content: "";
    
    /*Needed for the line-height to take effect*/
    display: inline-block;
}

/*Outer box of the fake checkbox*/
.checkbox label::before{
    height: 24px;
    width: 24px;
    
    border: 1px solid rgb(202, 202, 202);
    left: 0px;
    
    /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
     *to vertically center it.
     */
    top: 3px;
}

/*Checkmark of the fake checkbox*/
.checkbox label::after {
    height: 6px;
    width: 15px;
    border-left: 2px solid;
    border-bottom: 2px solid;
    
    transform: rotate(-45deg);
    
    left: 4px;
    top: 10px;
}

/*Hide the checkmark by default*/
.checkbox input[type="checkbox"] + label::after {
    content: none;
}

/*Unhide on the checked state*/
.checkbox input[type="checkbox"]:checked + label::after {
    content: "";
}

/*Adding focus styles on the outer-box of the fake checkbox*/
.checkbox input[type="checkbox"]:focus + label::before {
    //outline: rgb(59, 153, 252) auto 5px;
}

.button{
  text-transform: uppercase;
}










/***************END**************/