
.top-bar{
	background: rgba(255,255,255,0.5);
	@media (max-width: 639px){
		background: rgba(255,255,255,0.7);
	}
	transition: all 0.5s;
	.top-bar-title {
	    
	    padding: 0.5rem 1rem;
	}
	.logo{
		background: url(../img/common/logo-color.png);
		background-size:contain;
		background-repeat: no-repeat;
		width: 100px;
		height:0px;
		padding-bottom: 35%;
		transition: all 0.8s;
		&.careers{
			background: url(../img/common/logo-color-careers.png);
			background-size:contain;
			background-repeat: no-repeat;
		    width: 200px;
		    height: 0px;
		    padding-bottom: 17%;
			transition: all 0.8s;
		}
	}
	
	.menu-icon{
		margin: 20px;
		width: 20px;
    	height: 16px;
		&::after {
	     
		    background: #000;
		    box-shadow: 0 7px 0 #000, 0 14px 0 #000;
		 
		}
	}
 
				padding:0;
		 
	&.white{
		background: rgba(0,0,0,0.3);
		@media (max-width: 639px){
			background: rgba(0,0,0,0.5);

		}

		li{
			a{
				color: white;
			}
		}
		&.is-anchored{
			background: transparent;
			.logo{
			 
				width: 150px;
			 	&.careers{
					 
				    width: 320px;
				    height: 0px;
				    padding-bottom: 17%;
					transition: all 0.8s;
				}
			}
			@media (max-width: 639px){
				.top-bar-right{
					background: rgba(0, 0, 0, 0.5);
				    padding-top: 110px;
				    margin-top: -110px;
				    z-index: -1;
				    position: relative;
				}
			}
		}
		.logo{
			background: url(../img/common/logo-white.png);
			background-size:contain;
			background-repeat: no-repeat;
			&.careers{
				background: url(../img/common/logo-white-careers.png);
				background-size:contain;
				background-repeat: no-repeat;
				 
			}
		}
		.menu-icon::after {
		     
		    background: #fefefe;
		    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
		 
		}

	 
	}
	ul{
		background: transparent;
		text-align: right;
		@media (max-width: 639px){
			font-size:120%;
			li{
				display: block;
				text-align: center;
			}
		}
		li{


			a{
				color: black;

			}
		}
	}
}