$fs1: 5.25rem; //84px
$fs2: 4.5rem; //72px
$fs3: 3.75rem; //60px
$fs4: 3rem; //48px
$fs5: 2.5rem; //40px
$fs6: 2.125rem; //34px
$fs7: 1.5rem; //24px



$prefix: fs;

$font-list: (
  1  84,
  2  72,
  3  60,
  4  48,
  5  40,
  6  34,
  7  24,
  8  18,
  9  12
);

@each $value in $font-list {
  .#{$prefix}-#{nth($value, 1)} {
      font-size: (nth($value, 2)/16)#{em};
  }	
}

@media (max-width: 960px)and(min-width: 641px) {
	@each $value in $font-list {
	  .#{$prefix}-#{nth($value, 1)} {
	      font-size: (nth($value, 2)*0.8/16)#{em};
	  }	
	}
}
@media (max-width: 640px) {
	@each $value in $font-list {
	  .#{$prefix}-#{nth($value, 1)} {
	      font-size: (nth($value, 2)*0.6/16)#{em};
	  }	
	}
}

p{
  margin-bottom: 1em;

}

.lh-10{
  line-height: 1;
}
.lh-11{
  line-height: 1.1;
}
.lh-12{
  line-height: 1.2;
}
.lh-13{
  line-height: 1.3;
}
.lh-14{
  line-height: 1.4;
}
.lh-15{
  line-height: 1.5;
}
.lh-16{
  line-height: 1.6;
}
.lh-17{
  line-height: 1.7;
}
.lh-18{
  line-height: 1.8;
}
.lh-19{
  line-height: 1.9;
}
.lh-20{
  line-height: 2;
}

.mb-00{
  margin-bottom: 0;
}
.mb-02{
  margin-bottom: 0.2em;
}
.mb-03{
  margin-bottom: 0.3em;
}
.mb-05{
  margin-bottom: 0.5em;
}
.mb-10{
  margin-bottom: 1em;
}
.mb-15{
  margin-bottom: 1.5em;
}
.mb-20{
  margin-bottom: 2em;
}

.fw-1{font-weight:100;}
.fw-3{font-weight:300;}
.fw-4{font-weight:400;}
.fw-5{font-weight:500;}
.fw-6{font-weight:600;}
.fw-7{font-weight:700;}
