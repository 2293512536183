
html, body{
	//height:100%;
}
.sticky-container{
	position: absolute;
	width:100%;
	z-index: 1;
}

.menu > li > a {
    display: block;
    padding: 1rem 1rem;
    line-height: 1;
}
.noWrap{
	white-space: nowrap;
}
section{
 	padding: 100px 1em ;
 	overflow: hidden;
 	background: white;
 	position: relative;

 	@media (max-width: 1024px){
 		padding: 2em 1em ;
 	}
	
	background-size: cover;
	background-position: bottom center;
	
}

.full-section{
		min-height: 100vh;
		height: 100vh;
		box-sizing: border-box;
		.row{
			 height: 100%;
		}
	}
	.half-section{
		min-height:50vh;
		box-sizing: border-box;
		.row{
			 height: 100%;
		}
		@media (max-width: 639px){
			height:100vh;
		}
	}
	.threequarter-section{
		min-height: 75vh;
		
		box-sizing: border-box;
		.row{
			 height: 100%;
		}
		@media (max-width: 639px){
			height:100vh;
		}
	}

.row{
	&::after, &::before{
		display: block;
	}
}

.bg-wrap {
    clip: rect(0, auto, auto, 0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.fixed-img {
  position: fixed;
  display: block;
  z-index: -1;
  top: 0;
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  transform: translateZ(0);
}


ul{
	margin-left:30px;
	li{
		margin-bottom: 0.3em;
	}
}

ul.no-list{
	margin-left:0;
	li{
		list-style: none;
	}
}

.b-color{
	color: white;
	background-size: 100% 100%;
}
.b-img{
	color: white;
	background: transparent;
	//padding:0;
	.container{
		padding: 100px 1em ;
		height: 100%;
	}
}

.fade-back{
	&:before{
		content: "";
		width: 100%;
		height: 30vh;
		position: absolute;
		top: 0;
		left: 0;

		/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#00010b+0,00010f+100&1+0,0+100 */
		background: -moz-linear-gradient(top,  rgba(10,10,30,0.4) 0%, rgba(0,1,20,0) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(10,10,30,0.4) 0%,rgba(0,1,20,0) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(10,10,30,0.4) 0%,rgba(0,1,20,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00010b', endColorstr='#0000010f',GradientType=0 ); /* IE6-9 */

	}
}


 
	cite{
		margin-bottom: 1em;
		font-style: normal;
		color: #333333;
		@media (max-width:640px){
			font-size: 1.4em;
			line-height: 1.2;
			margin-bottom: 1em;
			display: block;
		} 

	}
	 




.center-content{
	display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row wrap;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    height: 100%;
    @extend .align-middle ;
  
    svg{
    	display: block;
    	width: 100%;
    }
}


 // WTF
.bcg {
	background-position: center center;
	background-repeat: no-repeat;
	
	background-size: cover;
	height: 100%;
	width: 100%;
}







