//Careers
#careers-jobs{
	h2{
		margin-bottom: 1em;
	}
}
.job-post{
	margin-bottom: 3em;
}
#careers-encourage{

}
#careers-intro{

}
#careers-offer{

}


#apply-form{
	.button{
		@extend .fs-7;
	}
}

.job-description{
	 
		margin: 2em 0;
	 
}

.button.upload-cv{
	background-color: $second-blue;
	background-color: rgba(39,65,125,0.7);
}


// dashboard
#dashboard{
	padding:3em 2em 2em 2em;
	.button{
		@extend .fs-7;
	}
	.test-note{
		font-weight: bold;
		@extend .fs-7;
		
		margin-bottom: 0.3em;
	}
	.test-timer{
		margin: 3em 0;
	}
	.test-timer__time{
		@extend .fs-3;
		@extend .fw-1;
		line-height: 1;
	}
	.test-timer__label{
		@extend .fs-7;
		margin-bottom: 0.3em;
	}
}
.dashboard-event{
	margin-bottom: 3em;
}
.dashboard-event__date{
	@extend .fs-9;

}
.dashboard-event__title{
	@extend .fs-6;
	line-height: 1.2;
	margin-bottom: 0.5em;
}
 

.dashboard-event__content{
	@extend .fs-8;

}

#dashboard-note{
	background: $light-gray;
	padding:2em;

}

.map p{
	//margin-bottom: 0.5rem;
}
