#contact{
	color: white;
	padding: 3rem;
	a{
		color: white;
		font-weight: bold;
		font-weight: 700;
	}
	p.contact-info{
		font-size: 1.8em;
		margin-top: 2em;
		@media (max-width: 639px){
				font-size: 1.3em;
		}
	}
	background-attachment: initial;
}